import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PlasmaTeamMember } from '../../models';

@Component({
  selector: 'pg-about-us-team-member',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './about-us-team-member.component.html',
  styleUrls: ['about-us-team-member.component.scss'],
})
export class AboutUsTeamMemberComponent {
  @Input() teamMember!: PlasmaTeamMember;
  constructor() {}
}
