<ngb-carousel [interval]="7000" [showNavigationArrows]="false" *ngIf="images">
  <ng-template ngbSlide>
    <img class="d-block w-100" [src]="images[4]" alt="Random first slide">
  </ng-template>
  <ng-template ngbSlide>
    <img class="d-block w-100" [src]="images[0]" alt="Random first slide">
  </ng-template>
  <ng-template *ngIf="getConferenceBanner() != -1" ngbSlide>
    <img *ngIf="getConferenceBanner() == 5" class="d-block w-100" [src]="images[5]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 6" class="d-block w-100" [src]="images[6]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 7" class="d-block w-100" [src]="images[7]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 8" class="d-block w-100" [src]="images[8]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 9" class="d-block w-100" [src]="images[9]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 10" class="d-block w-100" [src]="images[10]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 11" class="d-block w-100" [src]="images[11]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 12" class="d-block w-100" [src]="images[12]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 13" class="d-block w-100" [src]="images[13]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 14" class="d-block w-100" [src]="images[14]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 15" class="d-block w-100" [src]="images[15]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 16" class="d-block w-100" [src]="images[16]" alt="Random first slide">
    <img *ngIf="getConferenceBanner() == 17" class="d-block w-100" [src]="images[17]" alt="Random first slide">
  </ng-template>
  <ng-template ngbSlide>
    <img class="d-block w-100" [src]="images[2]" alt="Random first slide">
    <div id="career-banner">
      <div class="rounded" style="background-color: #ffffff44; height: fit-content;padding: 1.5rem 2rem;">
        <button routerLink="/careers" style="font-size: larger; font-weight: 500;" class="btn btn-lg btn-secondary">Click Here</button>
      </div>
    </div>
  </ng-template>
</ngb-carousel>