import { NgModule } from '@angular/core';
import { ApolloClientOptions, ApolloLink, from, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { LogoutUser } from '@modules/auth/store/loggedInUser.actions';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { ApolloLoadingInterceptorService } from '@app/apollo-loading-interceptor.service';

const uri = environment.API_URL;

export function createApollo(_httpLink: HttpLink, _store: Store, apolloLoadingInterceptorService: ApolloLoadingInterceptorService): ApolloClientOptions<any> {
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    //If there was an error, we want to make sure loading is turned off
    apolloLoadingInterceptorService.setLoadingIfLoadingMutation(false, operation);

    if (graphQLErrors)
      graphQLErrors.map(({ message }) => {
        switch (message) {
          case 'Unauthorized':
            console.log(`Received Unauthorized response calling GraphQL [${operation?.operationName}].  Explicitly logging user out.`);
            _store.dispatch(new LogoutUser(message));
            break;
          case 'Forbidden resource':
            _store.dispatch(new Navigate(['/error/403']));
            break;
          default:
            console.error(`[Unknown GraphQL Error]: ${operation?.operationName} - ${message}`);
            break;
        }
      });

    if (networkError) console.log(`[Network error]: ${operation?.operationName} - ${JSON.stringify(networkError)}`);
  });

  const loggingLink = new ApolloLink((operation, forward) => {
    console.log(`[GraphQL Request]: ${operation.operationName}`);
    return forward(operation);
  });

  const httpLink = _httpLink.create({
    uri,
    withCredentials: true,
  });

  const loadingLink = apolloLoadingInterceptorService.create();

  return {
    link: from([loggingLink, loadingLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: (environment as any)['APOLLO_DEVTOOLS'] || false,
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Store, ApolloLoadingInterceptorService],
    },
  ],
})
export class AppGraphQLModule {}
