import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChildActivationEnd, NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { AmplitudeAnalyticsService } from '@modules/analytics/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { AnalyticsService } from '@modules/analytics/services/analytics.service';
import { LoggedInUser, LoggedInUserState } from '@modules/auth/store';
import * as Sentry from '@sentry/angular-ivy';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  template: ` <sbpro-toast-view class="ngb-toasts" aria-live="polite" aria-atomic="true"></sbpro-toast-view>
    <router-outlet></router-outlet>
    <tour-step-template></tour-step-template>`,
})
export class AppComponent {
  private browserGlobals = {
    windowRef(): any {
      return window;
    },
  };

  @Select(LoggedInUserState.getLoggedInUser) loggedInUser$: Observable<LoggedInUser>;

  constructor(
    private analyticsService: AnalyticsService,
    private amplitudeAnalyticsService: AmplitudeAnalyticsService,
    public router: Router,
    private titleService: Title,
    private spinnerService: NgxSpinnerService
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd || event instanceof NavigationSkipped)).subscribe((event) => {
      this.pushGtmOnNavigationEnd();
      this.refreshHubSpot();
      this.spinnerService.hide();
      const { url } = event as NavigationEnd;
      if (!(url == '/student/sciops/play' || url == '/teacher/sciops/play' || url == '/district/sciops' || url == '/sciops')) {
        const musicPlayer = (window as any).musicPlayer;
        musicPlayer.stop();
      }
    });

    this.router.events.pipe(filter((event) => event instanceof ChildActivationEnd)).subscribe((event) => {
      let snapshot = (event as ChildActivationEnd).snapshot;

      while (snapshot.firstChild !== null) {
        snapshot = snapshot.firstChild;
      }
      this.titleService.setTitle(snapshot.data.title || 'Plasma Games');
    });

    this.loggedInUser$.subscribe((loggedInUser) => {
      this.updateSentryUserInfo(loggedInUser);
    });
  }

  ngOnInit(): void {
    this.amplitudeAnalyticsService.initAmplitude().then((result) => {
      console.log(`Initializing Amplitude with result: ${result}`);
    });
  }

  private pushGtmOnNavigationEnd() {
    this.analyticsService.pushRouteChangeEvent();
  }

  private refreshHubSpot() {
    this.browserGlobals.windowRef()?.HubSpotConversations?.widget?.refresh();
  }

  private updateSentryUserInfo(user: LoggedInUser | null) {
    if (user) {
      Sentry.setUser({ id: user?._id, email: user?.email, segment: user?.role });
    } else {
      Sentry.setUser(null);
    }
  }
}
