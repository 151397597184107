import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PlasmaCareers } from '../../data/plasmaCareers.data';

@Component({
  selector: 'pg-career-cards',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './career-card.component.html',
  styleUrls: ['career-card.component.scss'],
})
export class CareerCardComponent implements OnInit {
  careers = PlasmaCareers;
  constructor() {}
  ngOnInit() {}

  onNavigate(url: string) {
    window.open(url, '_blank');
  }
}
