import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, Injectable, Inject, Injector, NgModule, inject } from '@angular/core';
import { StateToken, actionMatcher, InitState, UpdateState, getValue, setValue, NGXS_PLUGINS } from '@ngxs/store';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { tap } from 'rxjs/operators';

const NG_DEV_MODE$4 = typeof ngDevMode === 'undefined' || ngDevMode;
const NGXS_STORAGE_PLUGIN_OPTIONS = new InjectionToken(NG_DEV_MODE$4 ? 'NGXS_STORAGE_PLUGIN_OPTIONS' : '');
const STORAGE_ENGINE = new InjectionToken(NG_DEV_MODE$4 ? 'STORAGE_ENGINE' : '');

/**
 * The following key is used to store the entire serialized
 * state when there's no specific state provided.
 */
const DEFAULT_STATE_KEY = '@@STATE';
function storageOptionsFactory(options) {
    return Object.assign({ key: [DEFAULT_STATE_KEY], storage: 0 /* LocalStorage */, serialize: JSON.stringify, deserialize: JSON.parse, beforeSerialize: obj => obj, afterDeserialize: obj => obj }, options);
}
function engineFactory(options, platformId) {
    if (isPlatformServer(platformId)) {
        return null;
    }
    if (options.storage === 0 /* LocalStorage */) {
        return localStorage;
    }
    else if (options.storage === 1 /* SessionStorage */) {
        return sessionStorage;
    }
    return null;
}
function getStorageKey(key, options) {
    // Prepends the `namespace` option to any key if it's been provided by a user.
    // So `@@STATE` becomes `my-app:@@STATE`.
    return options && options.namespace ? `${options.namespace}:${key}` : key;
}

/** Determines whether the provided key has the following structure. */
function isKeyWithExplicitEngine(key) {
    return key != null && !!key.engine;
}
/** This symbol is used to store the metadata on state classes. */
const META_OPTIONS_KEY = 'NGXS_OPTIONS_META';
function exctractStringKey(storageKey) {
    // Extract the actual key out of the `{ key, engine }` structure.
    if (isKeyWithExplicitEngine(storageKey)) {
        storageKey = storageKey.key;
    }
    // Given the `storageKey` is a class, for instance, `AuthState`.
    // We should retrieve its metadata and the `name` property.
    // The `name` property might be a string (state name) or a state token.
    if (storageKey.hasOwnProperty(META_OPTIONS_KEY)) {
        storageKey = storageKey[META_OPTIONS_KEY].name;
    }
    return storageKey instanceof StateToken ? storageKey.getName() : storageKey;
}

const NG_DEV_MODE$3 = typeof ngDevMode === 'undefined' || ngDevMode;
const FINAL_NGXS_STORAGE_PLUGIN_OPTIONS = new InjectionToken(NG_DEV_MODE$3 ? 'FINAL_NGXS_STORAGE_PLUGIN_OPTIONS' : '');
function createFinalStoragePluginOptions(injector, options) {
    const storageKeys = Array.isArray(options.key) ? options.key : [options.key];
    const keysWithEngines = storageKeys.map((storageKey) => {
        const key = exctractStringKey(storageKey);
        const engine = isKeyWithExplicitEngine(storageKey)
            ? injector.get(storageKey.engine)
            : injector.get(STORAGE_ENGINE);
        return { key, engine };
    });
    return Object.assign(Object.assign({}, options), { keysWithEngines });
}

const NG_DEV_MODE$2 = typeof ngDevMode === 'undefined' || ngDevMode;
class NgxsStoragePlugin {
    constructor(_options, _platformId) {
        this._options = _options;
        this._platformId = _platformId;
        this._keysWithEngines = this._options.keysWithEngines;
        // We default to `[DEFAULT_STATE_KEY]` if the user explicitly does not provide the `key` option.
        this._usesDefaultStateKey = this._keysWithEngines.length === 1 && this._keysWithEngines[0].key === DEFAULT_STATE_KEY;
    }
    handle(state, event, next) {
        var _a;
        if (isPlatformServer(this._platformId)) {
            return next(state, event);
        }
        const matches = actionMatcher(event);
        const isInitAction = matches(InitState);
        const isUpdateAction = matches(UpdateState);
        const isInitOrUpdateAction = isInitAction || isUpdateAction;
        let hasMigration = false;
        if (isInitOrUpdateAction) {
            const addedStates = isUpdateAction && event.addedStates;
            for (const { key, engine } of this._keysWithEngines) {
                // We're checking what states have been added by NGXS and if any of these states should be handled by
                // the storage plugin. For instance, we only want to deserialize the `auth` state, NGXS has added
                // the `user` state, the storage plugin will be rerun and will do redundant deserialization.
                // `usesDefaultStateKey` is necessary to check since `event.addedStates` never contains `@@STATE`.
                if (!this._usesDefaultStateKey && addedStates) {
                    // We support providing keys that can be deeply nested via dot notation, for instance,
                    // `keys: ['myState.myProperty']` is a valid key.
                    // The state name should always go first. The below code checks if the `key` includes dot
                    // notation and extracts the state name out of the key.
                    // Given the `key` is `myState.myProperty`, the `addedStates` will only contain `myState`.
                    const dotNotationIndex = key.indexOf(DOT);
                    const stateName = dotNotationIndex > -1 ? key.slice(0, dotNotationIndex) : key;
                    if (!addedStates.hasOwnProperty(stateName)) {
                        continue;
                    }
                }
                const storageKey = getStorageKey(key, this._options);
                let storedValue = engine.getItem(storageKey);
                if (storedValue !== 'undefined' && storedValue != null) {
                    try {
                        const newVal = this._options.deserialize(storedValue);
                        storedValue = this._options.afterDeserialize(newVal, key);
                    }
                    catch (_b) {
                        if (NG_DEV_MODE$2) {
                            console.error(`Error ocurred while deserializing the ${storageKey} store value, falling back to empty object, the value obtained from the store: `, storedValue);
                        }
                        storedValue = {};
                    }
                    (_a = this._options.migrations) === null || _a === void 0 ? void 0 : _a.forEach(strategy => {
                        const versionMatch = strategy.version === getValue(storedValue, strategy.versionKey || 'version');
                        const keyMatch = (!strategy.key && this._usesDefaultStateKey) || strategy.key === key;
                        if (versionMatch && keyMatch) {
                            storedValue = strategy.migrate(storedValue);
                            hasMigration = true;
                        }
                    });
                    if (!this._usesDefaultStateKey) {
                        state = setValue(state, key, storedValue);
                    }
                    else {
                        // The `UpdateState` action is dispatched whenever the feature
                        // state is added. The condition below is satisfied only when
                        // the `UpdateState` action is dispatched. Let's consider two states:
                        // `counter` and `@ngxs/router-plugin` state. When we call `NgxsModule.forRoot()`,
                        // `CounterState` is provided at the root level, while `@ngxs/router-plugin`
                        // is provided as a feature state. Beforehand, the storage plugin may have
                        // stored the value of the counter state as `10`. If `CounterState` implements
                        // the `ngxsOnInit` hook and calls `ctx.setState(999)`, the storage plugin
                        // will rehydrate the entire state when the `RouterState` is registered.
                        // Consequently, the `counter` state will revert back to `10` instead of `999`.
                        if (storedValue && addedStates && Object.keys(addedStates).length > 0) {
                            storedValue = Object.keys(addedStates).reduce((accumulator, addedState) => {
                                // The `storedValue` can be equal to the entire state when the default
                                // state key is used. However, if `addedStates` only contains the `router` value,
                                // we only want to merge the state with the `router` value.
                                // Let's assume that the `storedValue` is an object:
                                // `{ counter: 10, router: {...} }`
                                // This will pick only the `router` object from the `storedValue` and `counter`
                                // state will not be rehydrated unnecessary.
                                if (storedValue.hasOwnProperty(addedState)) {
                                    accumulator[addedState] = storedValue[addedState];
                                }
                                return accumulator;
                            }, {});
                        }
                        state = Object.assign(Object.assign({}, state), storedValue);
                    }
                }
            }
        }
        return next(state, event).pipe(tap(nextState => {
            if (isInitOrUpdateAction && !hasMigration) {
                return;
            }
            for (const { key, engine } of this._keysWithEngines) {
                let storedValue = nextState;
                const storageKey = getStorageKey(key, this._options);
                if (key !== DEFAULT_STATE_KEY) {
                    storedValue = getValue(nextState, key);
                }
                try {
                    const newStoredValue = this._options.beforeSerialize(storedValue, key);
                    engine.setItem(storageKey, this._options.serialize(newStoredValue));
                }
                catch (error) {
                    if (NG_DEV_MODE$2) {
                        if (error &&
                            (error.name === 'QuotaExceededError' ||
                                error.name === 'NS_ERROR_DOM_QUOTA_REACHED')) {
                            console.error(`The ${storageKey} store value exceeds the browser storage quota: `, storedValue);
                        }
                        else {
                            console.error(`Error ocurred while serializing the ${storageKey} store value, value not updated, the value obtained from the store: `, storedValue);
                        }
                    }
                }
            }
        }));
    }
}
/** @nocollapse */ NgxsStoragePlugin.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsStoragePlugin, deps: [{ token: FINAL_NGXS_STORAGE_PLUGIN_OPTIONS }, { token: PLATFORM_ID }], target: i0.ɵɵFactoryTarget.Injectable });
/** @nocollapse */ NgxsStoragePlugin.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsStoragePlugin });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsStoragePlugin, decorators: [{
            type: Injectable
        }], ctorParameters: function () { return [{ type: undefined, decorators: [{
                    type: Inject,
                    args: [FINAL_NGXS_STORAGE_PLUGIN_OPTIONS]
                }] }, { type: undefined, decorators: [{
                    type: Inject,
                    args: [PLATFORM_ID]
                }] }]; } });
const DOT = '.';

const NG_DEV_MODE$1 = typeof ngDevMode === 'undefined' || ngDevMode;
const USER_OPTIONS = new InjectionToken(NG_DEV_MODE$1 ? 'USER_OPTIONS' : '');
class NgxsStoragePluginModule {
    static forRoot(options) {
        return {
            ngModule: NgxsStoragePluginModule,
            providers: [
                {
                    provide: NGXS_PLUGINS,
                    useClass: NgxsStoragePlugin,
                    multi: true
                },
                {
                    provide: USER_OPTIONS,
                    useValue: options
                },
                {
                    provide: NGXS_STORAGE_PLUGIN_OPTIONS,
                    useFactory: storageOptionsFactory,
                    deps: [USER_OPTIONS]
                },
                {
                    provide: STORAGE_ENGINE,
                    useFactory: engineFactory,
                    deps: [NGXS_STORAGE_PLUGIN_OPTIONS, PLATFORM_ID]
                },
                {
                    provide: FINAL_NGXS_STORAGE_PLUGIN_OPTIONS,
                    useFactory: createFinalStoragePluginOptions,
                    deps: [Injector, NGXS_STORAGE_PLUGIN_OPTIONS]
                }
            ]
        };
    }
}
/** @nocollapse */ NgxsStoragePluginModule.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsStoragePluginModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule });
/** @nocollapse */ NgxsStoragePluginModule.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsStoragePluginModule });
/** @nocollapse */ NgxsStoragePluginModule.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsStoragePluginModule });
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "12.2.17", ngImport: i0, type: NgxsStoragePluginModule, decorators: [{
            type: NgModule
        }] });

const NG_DEV_MODE = typeof ngDevMode === 'undefined' || ngDevMode;
const LOCAL_STORAGE_ENGINE = new InjectionToken(NG_DEV_MODE ? 'LOCAL_STORAGE_ENGINE' : '', {
    providedIn: 'root',
    factory: () => (isPlatformBrowser(inject(PLATFORM_ID)) ? localStorage : null)
});
const SESSION_STORAGE_ENGINE = new InjectionToken(NG_DEV_MODE ? 'SESSION_STORAGE_ENGINE' : '', {
    providedIn: 'root',
    factory: () => (isPlatformBrowser(inject(PLATFORM_ID)) ? sessionStorage : null)
});

/**
 * The public api for consumers of @ngxs/storage-plugin
 */

/**
 * Generated bundle index. Do not edit.
 */

export { LOCAL_STORAGE_ENGINE, NGXS_STORAGE_PLUGIN_OPTIONS, NgxsStoragePlugin, NgxsStoragePluginModule, SESSION_STORAGE_ENGINE, STORAGE_ENGINE };

