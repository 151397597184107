import { Injectable } from '@angular/core';
import { ApolloLink, NextLink, Operation } from '@apollo/client/core';
import { LoadingStateService } from '@common/services/loading.state';

@Injectable({
  providedIn: 'root',
})
export class ApolloLoadingInterceptorService {
  constructor(private loadingStateService: LoadingStateService) {}

  create(): ApolloLink {
    return new ApolloLink((operation: Operation, forward: NextLink) => {
      const handleLoading: boolean = this.shouldHandleLoading(operation);
      if (handleLoading) {
        this.loadingStateService.setLoading(true);
      }
      return forward(operation).map((result) => {
        if (handleLoading) {
          this.loadingStateService.setLoading(false);
        }
        return result;
      });
    });
  }

  setLoadingIfLoadingMutation(loading: boolean, operation: Operation) {
    if (this.shouldHandleLoading(operation)) {
      this.loadingStateService.setLoading(loading);
    }
  }

  private shouldHandleLoading(operation: Operation): boolean {
    return this.isMutation(operation) && !this.ignoreLoading(operation);
  }

  private ignoreLoading(operation: Operation): boolean {
    return operation.getContext()?.ignoreLoading;
  }

  private isMutation(operation: Operation): boolean {
    return operation.query?.definitions.some((definition) => {
      return definition.kind === 'OperationDefinition' && definition.operation === 'mutation';
    });
  }
}
