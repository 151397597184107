import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

const conferenceBannerDates = [
  {
    name: 'conference_04',
    endDate: '2022-02-04',
  },
  {
    name: 'conference_05',
    endDate: '2022-02-09',
  },
  {
    name: 'conference_06',
    endDate: '2022-02-11',
  },
  {
    name: 'conference_07',
    endDate: '2022-02-18',
  },
  {
    name: 'conference_08',
    endDate: '2022-02-19',
  },
  {
    name: 'conference_09',
    endDate: '2022-02-24',
  },
  {
    name: 'conference_10',
    endDate: '2022-02-27',
  },
  {
    name: 'conference_11',
    endDate: '2022-03-04',
  },
  {
    name: 'conference_12',
    endDate: '2022-03-11',
  },
  {
    name: 'conference_13',
    endDate: '2022-03-15',
  },
  {
    name: 'conference_14',
    endDate: '2022-03-25',
  },
  {
    name: 'conference_15',
    endDate: '2022-03-29',
  },
  {
    name: 'conference_16',
    endDate: '2022-03-31',
  },
];
@Component({
  selector: 'pg-banner-carousel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './banner-carousel.component.html',
  styleUrls: ['banner-carousel.component.scss'],
})
export class BannerCarouselComponent implements OnInit {
  images = [
    'award-wide',
    'sciops',
    'career-wide',
    'vascd-wide',
    'plasma',
    'conference_04',
    'conference_05',
    'conference_06',
    'conference_07',
    'conference_08',
    'conference_09',
    'conference_10',
    'conference_11',
    'conference_12',
    'conference_13',
    'conference_14',
    'conference_15',
    'conference_16',
  ].map((n) => `/assets/img/home/banner/${n}.png`);
  constructor() {}
  ngOnInit() {}

  getConferenceBanner() {
    const today = moment().startOf('D');
    const bannerNameArr = conferenceBannerDates.filter((e) => {
      return !moment(e.endDate).startOf('D').isBefore(today);
    });
    if (bannerNameArr.length > 0) {
      const bannerName = bannerNameArr[0].name;
      return this.images.findIndex((value) => value == `/assets/img/home/banner/${bannerName}.png`);
    } else {
      return -1;
    }
  }
}
