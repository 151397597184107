import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LogUtilService } from '@modules/util/log-util.service';

@Injectable()
export class GraphqlInterceptorService implements HttpInterceptor {
  constructor() {}

  static FIELDS_TO_OBFUSCATE = ['email', 'password', 'username', 'firstName', 'lastName'];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request is a GraphQL request
    if (request.body && request.body.operationName) {
      // Clone the request to add withCredentials
      const graphqlReq = request.clone({
        withCredentials: true,
      });

      return next.handle(graphqlReq).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            // Check for errors in the response body
            const responseBody = event.body;
            if (responseBody.errors) {
              //Obfuscate any PII that may be in the errors array
              const obfuscatedRequest = LogUtilService.obfuscateJsonObject(request.body, GraphqlInterceptorService.FIELDS_TO_OBFUSCATE);
              const obfuscatedErrors = LogUtilService.obfuscateJsonObject(responseBody.errors, GraphqlInterceptorService.FIELDS_TO_OBFUSCATE);
              //Sentry max is 200KB.  So truncate the sum of these requests < 200
              const requestToLog = this.checkAndTruncateBody(obfuscatedRequest, 50);
              const errorsToLog = this.checkAndTruncateBody(obfuscatedErrors, 100);
              console.error(`Error while calling: ${requestToLog}`);
              console.error(`XHR Error: ${errorsToLog}`);
            }
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }

  private checkAndTruncateBody(body: any, kilobytes: number): any | null {
    const MAX_SIZE = kilobytes * 1024 * 0.8; // Give 80% of that to allow for overhead
    const bodyString = JSON.stringify(body);
    if (new Blob([bodyString]).size <= MAX_SIZE) {
      return body;
    } else {
      return 'Truncated due to size limit of body'; // or return a truncated version
    }
  }
}
