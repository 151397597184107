import { Injectable } from '@angular/core';

@Injectable()
export class LogUtilService {
  constructor() {}

  static obfuscateJsonObject(jsonObj: any, fieldNames: string[]) {
    try {
      // Function to recursively obfuscate specified fields
      function obfuscateFields(obj: any) {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            if (fieldNames.includes(key)) {
              const valueToObfuscate = obj[key] || '';
              obj[key] = LogUtilService.obfuscateValue(valueToObfuscate);
            } else if (typeof obj[key] === 'object') {
              // Recursively obfuscate fields in nested objects or arrays
              obfuscateFields(obj[key]);
            }
          }
        }
      }
      obfuscateFields(jsonObj);
      return JSON.stringify(jsonObj) || '';
    } catch (e) {
      console.error('Error obfuscating fields', e);
      return '';
    }
  }

  static obfuscateJsonString(jsonString: string, fieldNames: string[]): string {
    try {
      const jsonObj = JSON.parse(jsonString);
      return this.obfuscateJsonObject(jsonObj, fieldNames);
    } catch (e) {
      console.error('Error obfuscating fields', e);
      return jsonString || '';
    }
  }

  private static obfuscateValue(value: string): string {
    if (value.length <= 1) {
      return value;
    }
    // For strings of length 2 or more, return the first character, the obfuscated part, and the last character
    return value[0] + '*'.repeat(value.length - 2) + value[value.length - 1];
  }
}
