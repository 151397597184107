import { PlasmaCareer } from '../models/career.model';

export const PlasmaCareers: PlasmaCareer[] = [
  // {
  //   title: 'Curriculum and Integration Specialist (High School)',
  //   location: 'Raleigh, NC',
  //   link:
  //     'https://www.indeed.com/viewjob?t=curriculum+and+integration+specialist+high+school+science+hybrid&jk=6dca152b5d74d2bd&_ga=2.195140334.359586512.1645726868-479340641.1619550311',
  // },
  // {
  //   title: 'Curriculum and Integration Specialist (Middle School)',
  //   location: 'Raleigh, NC',
  //   link:
  //     'https://www.indeed.com/viewjob?t=curriculum%20and%20integration%20specialist%20middle%20school%20science%20hybrid&jk=c7b46fa3a327f2bf&_ga=2.195737070.359586512.1645726868-479340641.1619550311',
  // },
  // {
  //   title: 'District Relations Specialist',
  //   location: 'Raleigh, NC',
  //   link: 'https://www.indeed.com/viewjob?cmp=Plasma-Games&t=District+Relation+Specialist&jk=402b408fbcd630e7&q=plasma+games&vjs=3',
  // },
];
